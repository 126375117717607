import React from "react";
import { useI18next } from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import CookieBar from "../../components/cookiebar";
import { graphql, useStaticQuery } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getResource } from "../../helpers/resourceHelper";
import backgroundImage from "../../images/404.png";
import '../../scss/pages/404.scss';

const query = graphql`
  query Notfound {
    resources: allContentfulResource(
      filter: { key: { in: ["404.title", "404.description"] } }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

const NotFoundComponent = () => {
  const { language } = useI18next();
  const { resources } = useStaticQuery(query);

  return (
    <Layout language={language} className="layout-notfound">
      <div className="notfound-container">
        <div className="notfound__title">
          {documentToReactComponents(getResource(resources.edges, "404.title", language))}
        </div>
        <div className="notfound__image">
          <img alt="error" src={backgroundImage} />
          <span className="notfound__text">40</span>
          <span>4</span>
        </div>
        <div className="notfound__description">
          {documentToReactComponents(getResource(resources.edges, "404.description", language))}
        </div>
      </div>
      <CookieBar language={language} />
    </Layout>
  )
}

export default NotFoundComponent
